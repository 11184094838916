import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getMonthlyAccountStatement,
  getCreditStatement,
} from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";

function AccountStatement() {
  const gridRef = useRef<AgGridReact>(null);
  const gridCreditRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridCredApi, setGridCredApi] = useState<any>(null);
  const myRef = useRef<any>();
  const myCredRef = useRef<any>();
  const dispatch = useAppDispatch();
  const accountStatementCols = (JSON.parse(localStorage.getItem('accountStatementCol') || '[]'));
  const accountStatementCredCols = (JSON.parse(localStorage.getItem('accountStatementCredCol') || '[]'));
  
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "MonthlyAccountStatement.csv",
    URL: "Reports/GetReturnStatements",
    filterOptions: [],
  });
  const [gridCredData, setGridCredData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "CreditStatement.csv",
    URL: "Reports/GetStatementCredits",
    filterOptions: [],
  });
  const columnItems = [
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: accountStatementCols.includes("customerName"),
      comparator: customComparator,
    },
    {
      headerName: "Location Serviced",
      field: "locationsServiced",
      headerTooltip: "Location Serviced",
      tooltipShowDelay: 0,
      hide: accountStatementCols.includes("locationsServiced"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Returns Last Month",
      headerTooltip: "Returns Last Month",
      tooltipShowDelay: 0,
      field: "returnsLastMonth",
      hide: accountStatementCols.includes("returnsLastMonth"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Units Last Month",
      headerTooltip: "Units Last Month",
      tooltipShowDelay: 0,
      field: "unitsLastMonth",
      hide: accountStatementCols.includes("unitsLastMonth"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "ERV Last Month",
      headerTooltip: "ERV Last Month",
      tooltipShowDelay: 0,
      field: "ervLastMonth",
      hide: accountStatementCols.includes("ervLastMonth"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Pharmaceutical Waste Last Month",
      headerTooltip: "Pharmaceutical Waste Last Month",
      tooltipShowDelay: 0,
      field: "pharmaceuticalWaste",
      hide: accountStatementCols.includes("pharmaceuticalWaste"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
  ];
  const columnCredItems = [
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      hide: accountStatementCredCols.includes("customerId"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Disbursement Type",
      headerTooltip: "Disbursement Type",
      tooltipShowDelay: 0,
      field: "disbursementType",
      hide: accountStatementCredCols.includes("disbursementType"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Reference Number",
      headerTooltip: "Reference Number",
      tooltipShowDelay: 0,
      field: "referenceNumber",
      hide: accountStatementCredCols.includes("referenceNumber"),
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Net Amount",
      headerTooltip: "Net Amount",
      tooltipShowDelay: 0,
      field: "netAmount",
      hide: accountStatementCredCols.includes("netAmount"),
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
  ];
  const localdata: any = localStorage.getItem("MonthlyAccountStatementCol");
  const [colDefs, setColDefs] = useState(columnItems);
  const [colCredDefs, setColCredDefs] = useState(columnCredItems);
  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    // setTimeout(() => {
    //   params.api.autoSizeAllColumns();
    // }, 100);
  };

  const onGridCredReady = (params: any) => {
    setGridCredApi(params.api);
    // setTimeout(() => {
    //   params.api.autoSizeAllColumns();
    // }, 100);
  };

  const updateGridCredData = (k: any, v: any) =>
    setGridCredData((prev) => ({ ...prev, [k]: v }));

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("accountStatementCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };
  const popupCredSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("accountStatementCredCol", JSON.stringify(cols));
    toast.success("Saved Successfully");
    setColCredDefs(columnValues);
    myCredRef?.current.handleClose();
    if (gridCreditRef.current) {
      gridCreditRef.current.api.autoSizeAllColumns();
    }
  };
  const MonthlyAccountStatement = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              GlobalSearch: gridData.globalSearch,
              IsForExcel: gridData.isForExcel,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getMonthlyAccountStatement(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData("rows", result?.payload?.data?.returnStatement);

              if (result?.payload?.data?.returnStatement?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.returnStatement,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };
  const MonthlyCreditStatement = async () => {
    try {
      if (gridCredApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridCredApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridCredApi.paginationGetCurrentPage() + 1,
              PageSize: gridCredData.pageSize,
              GlobalSearch: gridCredData.globalSearch,
              IsForExcel: gridCredData.isForExcel,
              SortField: gridCredData.sortField,
              sortDirection: gridCredData.sortOrder
                ? parseInt(gridCredData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridCredData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getCreditStatement(data)).then((result: any) => {
              updateGridCredData(
                "totalRows",
                result?.payload?.data?.totalCount
              );
              updateGridCredData(
                "rows",
                result?.payload?.data?.creditStatement
              );
              if (result?.payload?.data?.creditStatement?.length === 0) {
                gridCredApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridCredApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.creditStatement,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridCredApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        MonthlyAccountStatement();
      }, 2000);
      return () => clearTimeout(getData);
    } else {
      MonthlyAccountStatement();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridCredData.globalSearch) {
      const getData = setTimeout(() => {
        MonthlyCreditStatement();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      MonthlyCreditStatement();
    }
  }, [
    gridCredData.page,
    gridCredData.pageSize,
    gridCredData.sortOrder,
    gridCredData.sortField,
    gridCredData.globalSearch,
    gridCredApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
    if (gridCredData.isForExcel === true) {
      getFiles(
        gridCredData,
        visibleColumnNames(colCredDefs),
        formateIds(userAccounts)
      );
      updateGridCredData("isForExcel", false);
    }
  }, [gridData.isForExcel, gridCredData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };
  const onSortCredChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridCredData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridCredData("sortField", colId);
    }
  };
  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const globalCredSearch = (e: any) => {
    updateGridCredData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onCredExport = (e: any) => {
    updateGridCredData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const onClearCredFilter = () => {
    gridCredApi!.setFilterModel(null);
    updateGridCredData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <PageTitle
        name="Monthly Account Summary/Statement"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <PageTitle name="Return Summary Data"></PageTitle>
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
      <br />
      <br />
      <div className="content_body grid-reset">
        <PageTitle name="Total Credits Data"></PageTitle>
        <GridComponent
          ref={myCredRef}
          gridRef={gridCreditRef}
          rowData={gridCredData.rows}
          colDefs={colCredDefs}
          onGridReady={onGridCredReady}
          popupSave={popupCredSave}
          paginationPageSize={gridCredData.pageSize}
          paginationPageSizeSelector={gridCredData.rowsPerPageOptions}
          shortOrder={gridCredData.sortOrder}
          sortChange={onSortCredChanged}
          searchId={"filter-text-box-cred"}
          globalSearch={globalCredSearch}
          onExport={onCredExport}
          gridData={gridCredData}
          setGridData={setGridCredData}
          onClearFilter={onClearCredFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default AccountStatement;
